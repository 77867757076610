import { isLoaded, isLoading } from "../actions";
import Alert from "../../Alert";
import API from "../../Network/API";
// Add user story from Template
export async function addUserStoryTemplate(state, dispatch, getUser,handleClose, storyTitle, MAINTASK_ADD, is_workflow = 0,MAINTASK,PENDING_SUBTASKS) {
  if(storyTitle !== state.taskTitle.value){
    dispatch(isLoading());
     if (state.taskTitle.value.trim() !== "" && state.taskDescription.value.trim() !== "" && state.acceptanceCriteria.value.trim() !== "") {
      try {
        var response = await API.post("manage_userstories.php", {
          crop: getUser.corp,
          action: "add",
          title: state.taskTitle.value,
          description: state.taskDescription.value,
          added_by: getUser.empId,
          acceptanceCriteria: state.acceptanceCriteria.value,
          added_to: state.userSelected === "" ? state.id.value : state.userSelected.value,
          storyPoints: state.storySelected === "" ? state.storyPoints.value : state.storySelected,
          priorityLevel: state.prioritySelected === "" ? state.priorityLevel.value : state.prioritySelected,
          idea_id: state.epicSelected === "" ? state.ideaId.value : state.epicSelected,
          "projectId":getUser.projectId,
          device_id: state.device_id,
          player_id: state.player_id,
          is_workflow: is_workflow,
          main_task_id: 0,
          message: `This ${MAINTASK} added in ${PENDING_SUBTASKS}`,
          module_id: state.sprints.length > 0 ? state.sprints[0].moduleId : null,
          target_date: state.sprints.length > 0 ? state.sprints[0]?.targetDate : null,
        }, {}, false);
        if (response.status === 'True') {
          Alert("success", MAINTASK_ADD);
        }
      } catch (error) {
        Alert('error', error.message);
        dispatch(isLoaded());
      }
      handleClose()
    }
   
    else {
      Alert("warning", "Please enter required fields ")
      dispatch(isLoaded());
    }
  }else{
    Alert("warning", "Please change user story title ")
    dispatch(isLoaded());
  }
  }