import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useSelector } from 'react-redux';
import { getSubStringId } from "../../Common/SubStringConvert";
import { useWebProperties } from '../../Common/webProperties';
import { isMobile } from 'react-device-detect';
import { updateSetDone, updateUserStory } from './Services/updateUserStory';
import { pauseUserStory } from './Services/pauseUserStory';
import { Reports_URL } from '../../Common/config';
import Axios from 'axios';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2, 4, 3),
    },
}));

//For Add New Project
export default function MyTaskerCompleteReason(props) {
    const getUser = useSelector(state => state.auth)
    const [taskDescription, setTaskDescription] = useState('')
    const [errorMessage, setErrorMessage] = useState(null)
    const { MAINTASK, TASKS_WARNING, ROADBLOCK_WARNING, SUBTASK } = useWebProperties();
    // eslint-disable-next-line
    const classNames = useStyles();
    const [loader, setLoader] = useState(false)
    const activities = useSelector(state => state.landingReducer.userActivities)
    const [goingOpen, setGoingOpen] = useState(false)
    const [pause, setPause] = useState(false)
    const [complete, setComplete] = useState(false)
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState({});
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            await getCompleteResons()
        }
        fetchData()
        // eslint-disable-next-line
    },[])
 
    const completeTask = () => {
        setLoader(true)
        if (!taskDescription || taskDescription.trim() === "") {
            setErrorMessage(`Please enter complete reason`)
            setLoader(false)
        } else {
            setErrorMessage(null)
            if (props.data.task_type === 'sub_task') {
                let message = `${taskDescription}, ${SUBTASK} is completed`;
                updateSetDone(activities, getUser.user, props.data.task_id, props.data.story_id, props.handleClose, message, SUBTASK)
            } else {
                let message = `${taskDescription}, ${MAINTASK} is completed`;
                updateUserStory(activities, getUser.user, props.data.story_id, props.handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, props.data.story_type, props.data.us_id, props.data.main_task_id, MAINTASK)
            }
        }
    }

    const pauseTask = () => {
        setLoader(true)
        if (!taskDescription || taskDescription.trim() === "") {
            setErrorMessage(`Please enter pause reason`)
            setLoader(false)
        } else {
            setErrorMessage(null)
            if (props.data.task_type === 'sub_task') {
                let message = `${taskDescription}, ${SUBTASK} is paused`;
                pauseUserStory(activities, getUser.user, "", props.data.task_id, props.data.assignedTo, props.handleClose, message, MAINTASK, props.data.task_type, props.data.task_type, SUBTASK)
            } else {
                let message = `${taskDescription}, ${MAINTASK} is paused`;
                pauseUserStory(activities, getUser.user, "", props.data.story_id, props.data.assignedTo, props.handleClose, message, MAINTASK, props.data.task_type, props.data.task_type, SUBTASK)
            }
        }
    }

    const getCompleteResons=async()=>{
        setLoader(true)
        try {
          var response = await Axios.post(`${Reports_URL}/ai/getTaskCompletionQuestions`, {
            "acceptance_criteria": props.data.acceptance_criteria,
            "role":"Developer"
          }, {}, false);
          const data=response.data.questions.map((q,index) => {
            return {id:index,question:q}
          })
          setQuestions(data)
          if (Array.isArray(data)){
            const result=data.reduce((acc, q) => {
                acc[q.id] = '';
                return acc;
              }, {})
            setAnswers(result)
          }
          setLoader(false)
        } catch (error) {
          setLoader(false)
          console.log(error)
        }
      }

    const handleInputChange = (id, value) => {
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [id]: value,
        }));
    };

    const validateAnswers = () => {
        const newErrors = {};
        questions.forEach((q) => {
            if (!answers[q.id]) {
            newErrors[q.id] = `* Required field`;
            }
        });
        return newErrors;
    };

    const completeTaskQuestions = async () => {
        const validationErrors = validateAnswers();
        if (Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
          return;
        }else{
            let description=''
            questions.forEach((q) => {
                description+=`${answers[q.id]},`
            })
            if (props.data.task_type === 'sub_task') {
                let message = `${description}, ${SUBTASK} is completed`;
                updateSetDone(activities, getUser.user, props.data.task_id, props.data.story_id, props.handleClose, message, SUBTASK)
            } else {
                let message = `${description}, ${MAINTASK} is completed`;
                updateUserStory(activities, getUser.user, props.data.story_id, props.handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, props.data.story_type, props.data.us_id, props.data.main_task_id, MAINTASK)
            }
        }
    }

    const moveToPause = () => {
        setGoingOpen(true)
        setPause(true)
    }
    const moveToComplete = () => {
        setGoingOpen(true)
        setComplete(true)
    }
    const moveToBack = () => {
        setGoingOpen(false)
        setPause(false)
        setComplete(false)
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center">
                            <div className={isMobile ? `modal-content p-2` : `modal-content col-5 p-2`} style={{ borderRadius: '10px' }}>
                                <div class="modal-header" style={{ backgroundColor: '#D2ECDF' }}>
                                    <h5 class="modal-title p-2">{getSubStringId(props.data.project_name, 3)}{'-'}{props.data.main_task_id !== null && props.data.main_task_id !== "0" ? `${props.data.main_task_id}-` : ''}{props.data.us_id}{'-'}{props.data.story_title}{props.data.blocked_id !== null && <span style={{ width: '100px', cursor: 'pointer', fontWeight: 'bold', color: 'white', backgroundColor: 'rgb(255, 170, 51)', padding: '3px 5px', marginLeft: 5, textAlign: 'center' }} >{getSubStringId(props.data.project_name, 3)}{'-'}{props.data.userStoryId}</span>}</h5>

                                </div>
                                {goingOpen?<div>
                                    {pause&&<div className="modal-body">
                                        <label>Pause Reason</label>
                                        <textarea className='form-control' onChange={(event) => setTaskDescription(event.target.value)} />
                                        {
                                        taskDescription.trim() ==='' &&
                                        errorMessage !== null && <label style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</label>}
                                    </div>}
                                    {complete&&<div className="modal-body">
                                        {!questions.length>0?<div>
                                            <label>Complete Reason</label>
                                            <textarea className='form-control' onChange={(event) => setTaskDescription(event.target.value)} />
                                            {
                                            taskDescription.trim() ==='' &&
                                            errorMessage !== null && <label style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</label>}
                                        </div>:
                                        <div>
                                            {questions?.map((q) => (
                                                <div key={q.id} className="question">
                                                <label>{q.id+1}.{q.question}</label>
                                                <textarea
                                                    className='form-control'
                                                    value={answers[q.id] || ''}
                                                    onChange={(e) => handleInputChange(q.id, e.target.value)}
                                                />
                                                {/* Display error message if exists */}
                                                {errors[q.id] && <p style={{ color: 'red' }}>{errors[q.id]}</p>}
                                                </div>
                                            ))}
                                        </div>}
                                    </div>}
                                    <div class="modal-footer justify-space-between">
                                        <button type="button" class="btn btn-outline-danger" onClick={() =>moveToBack()} style={{ borderRadius: '20px' }}>Cancel</button>
                                        {pause&&<button disabled={loader} type="button" class="btn btn-outline-info" style={{ borderRadius: '20px' }} onClick={() => {
                                            pauseTask()
                                        }}  >Pause</button>}
                                        {complete&&<button disabled={loader} type="button" class="btn btn-outline-success" style={{ borderRadius: '20px' }} onClick={() => {
                                            if(questions.length>0){
                                                completeTaskQuestions()
                                            }else{
                                                completeTask()
                                            }
                                        }}  >Complete</button>}
                                    </div>
                                </div>:
                                <div >
                                    <div style={{display:'flex',justifyContent:'center',marginTop:10}}>
                                        <button disabled={loader} type="button" class="btn btn-outline-info" style={{ borderRadius: '20px',width:'150px',marginRight:5 }} onClick={() => {
                                            moveToPause()
                                        }}  >Pause</button>
                                        <button disabled={loader} type="button" class="btn btn-outline-success" style={{ borderRadius: '20px',width:'150px' }} onClick={() => {
                                            moveToComplete()
                                        }}  >Complete</button>
                                    </div>
                                    <div style={{textAlign:'end',marginTop:10}}>
                                        <button type="button" class="btn btn-outline-danger" onClick={() => props.handleClose()} style={{ borderRadius: '20px' }}>Cancel</button>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div >
    );
}
