import ReportsAPI from "./AgileNetwork/ReportsAPI"
import { userIPInfoURL } from "./config"
import { getWebProperties } from "./getProperties"
import { getToken, setToken } from "./LocalStorage"
import API from "./Network/API"

export const logEntry= async (logsData,getUser)=>{
    try {
        let userData= JSON.parse(getToken('userIPInfo'))
        let webproperties = JSON.parse(getToken('web_properties')) || getWebProperties();
        if(!userData) {
            userData=await API.get(userIPInfoURL)
            setToken('userIPInfo',JSON.stringify(userData))
        }
        const logRecord={
            ...logsData,
            timestamp:new Date(),
            account_id:getUser.user ? getUser.user.account_id : getUser.account_id,
            application:webproperties.APP_NAME || "undefined",
            userInfo:{
                ip:userData.ip,
                location:`${userData.city}-${userData.region}-${userData.country_name}`
            }
        }
        await ReportsAPI.post('/logs/insertLogs',logRecord)

    } catch (error) {
        console.log(error)
    }
}