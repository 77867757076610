import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import API from '../Network/API';
import RootLoader from '../Loader/RootLoader';

const EventEmailRespond = ({responseSend,updateResponseState}) => {
  const query = new URLSearchParams(useLocation().search);
  const reply = query.get('response');
  const email = query.get('email');
  const eventId = query.get('eventId');
  //const type = query.get('type');
  const corp_code=query.get('corp_code')
  console.log(email,"email sending")
  const [response, setResponse] = useState(false);
  const [messageSent, setMessageSent] = useState(false);

  useEffect(()=>{
    const fetchData = async () => {
      if (messageSent) return;
      setMessageSent(true);
        try {
          // Step 1: Fetch user info
          const userResponse = await API.post('get_user.php', {
            action: 'get_user_with_email',
            email: email,
          }, {}, false);
          
          if (userResponse.status === 'True') {
            const employeId = userResponse.data[0].empid;
  
            // Step 2: Fetch task details
            const taskResponse = await API.post('calendarDash.php', {
              action: 'getEventInfoWithId',
              event_id: eventId,
            }, {}, false);
  
            if (taskResponse.status === 'True') {
              const storyId = taskResponse.task_details[0].us_id;
  
              // Step 3: Send chat message
              let message;
              if (reply === 'yes') {
                message = 'Hello, thank you for the invite. I confirm my attendance and look forward to our meeting.';
              } else if (reply === 'no') {
                message = 'Hello, thank you for the invitation. Unfortunately, I won’t be able to attend. Please feel free to share any important notes or updates.';
              }
  
              const messageReply = await API.post("user_story_chat.php", {
                action: "send",
                corp_code: corp_code,
                groupId: storyId,
                message: message,
                messagedBy: employeId,
                msgId: " ",
                groupName: '',
                groupEmail: '',
                reply_id: "",
              }, {}, false);
  
              if (messageReply.status === "True") {
                updateResponseState(true)
                setTimeout(() => window.close(), 5000);
              } else {
                updateResponseState(false)
                setResponse(true);
                setTimeout(() => window.close(), 5000);
              }
            } else {
                updateResponseState(false)
                setResponse(true);
                setTimeout(() => window.close(), 5000);
            }
          } else {
            updateResponseState(false)
            setResponse(true);
            setTimeout(() => window.close(), 5000);
          }
        } catch (error) {
            updateResponseState(false)
            setResponse(true);
            setTimeout(() => window.close(), 5000);
        }
      };
      if (!responseSend) {
        fetchData();
      }
      //eslint-disable-next-line
  },[messageSent])

  return (
    <div style={{height:'400px',width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
        <div >
            <div>
            {(!responseSend && !response)? <RootLoader/>:null}
            </div>
            {/* <p>Sending response</p> */}
            {responseSend && (<div style={{textAlign:'center'}}>
            <p style={{ color: 'green', fontWeight: 'bold' , fontSize:'30px'}}>
                Response submitted successfully!
            </p>
            </div>
            )}
            {(!responseSend && response)&&(
            <p style={{ color: 'red', fontWeight: 'bold',fontSize:'30px' }}>
                Response not submitted!
            </p>
            )}
        </div>
    </div>
  );
};

export default EventEmailRespond;