import moment from "moment";

export const filteredData = (data) => {
  let returnArray = data?.sort((a, b) => {
    // Convert 'us_id' to numbers and compare them for sorting.
    return parseInt(b.us_id || b.id) - parseInt(a.us_id || a.id);
  })
  return returnArray;
}
export const removeSpecialCharacters = (inputString) => {
  return inputString.replace(/[^\w\s]/g, '');
}
export const removeDuplicatesFromString = (string) => {
  const namesAndEmailsString = string;

  // Split the string into an array
  const namesAndEmailsArray = namesAndEmailsString.split(',');

  // Remove duplicates
  const uniqueNamesAndEmailsArray = [...new Set(namesAndEmailsArray)];

  // Join the unique array back into a string
  const uniqueNamesAndEmailsString = uniqueNamesAndEmailsArray.join(',');

  return uniqueNamesAndEmailsString;

}

export const getCompleteData = (array1, array2, getUser) => {

  let currentGroupTasksData = array2?.filter(item => {
    return item.main_task_id !== '0' && item.assignedTo === getUser?.user?.empId && item.story_type === 'group'
  }) || []

  // eslint-disable-next-line
  let currentGroupTasksSelfData = array2?.filter(item => {
    return item.main_task_id === '0' && item.assignedTo === getUser?.user?.empId && item.story_type === 'group'
  }) || []

  let currentPendingTasks = array1 || []

  let currentGroupTasks = currentPendingTasks.concat(currentGroupTasksData)

  // return currentGroupTasks
  // enable below line to show group maintask
  return currentGroupTasks.concat(currentGroupTasksSelfData)
}

export const getCompleteRoadblockData = (array1, array2, getUser) => {

  let currentGroupTasksData = array2?.filter(item => {
    return item.main_task_id !== '0' && item.metadata.assigned_to === getUser?.user?.empId && item.metadata.story_type === 'group'
  }) || []

  // eslint-disable-next-line
  let currentGroupTasksSelfData = array2?.filter(item => {
    return item.main_task_id === '0' && item.metadata.assigned_to === getUser?.user?.empId && item.metadata.story_type === 'group'
  }) || []

  let currentPendingTasks = array1 || []

  let currentGroupTasks = currentPendingTasks.concat(currentGroupTasksData)

  // return currentGroupTasks
  // enable below line to show group maintask
  return currentGroupTasks.concat(currentGroupTasksSelfData)
}
export const capitalizeFirstLetter = (str) => {

  if (str === null || str === "" || str === undefined) {
    return ""; // Return an empty string for null or empty input
  }

  if (str.charAt(0) === ' ') {
    // If the first character is a space, return the string without capitalizing it
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const isValidEmail = (email) => {
  // Regular expression for basic email validation
  var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export const getEmailDomain = (email) => {
  const parts = email.split("@");
  return parts[1];
}

/* commented bcz of not used any where in the application and for removed terminal warnings
function formatTimestamp(messaged_time) {
  const dateParts = messaged_time.split(/[ /:]/); // Split the timestamp string by space, /, and :
  const year = dateParts[2];
  const month = dateParts[0];
  const day = dateParts[1];
  const hour = dateParts[3];
  const minute = dateParts[4];
  const second = dateParts[5].substring(0, 2); // Remove the timezone part (e.g., "IST")

  // Construct the formatted date string
  const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')} ${hour.padStart(2, '0')}:${minute.padStart(2, '0')}:${second.padStart(2, '0')}`;

  return formattedDate;
} 
*/

// Define the backgroundCardColor function
export const backgroundCardColor = (messaged_time, story_type, complete_status, messages, us_id, activeStatus, messaged_by) => {
  let count = 0;

  for (const message of messages) {
    if (message.messagedBy !== messaged_by && message.groupId === us_id) {
      count++;
    }
  }
  if (count >= 1) {
    return 'white'
  } else {
    if (messaged_time !== null) {
      const date = new Date(messaged_time);
      const currentTime = new Date();

      // Calculate the difference in seconds between current time and message time
      const diffSeconds = Math.abs((currentTime - date) / 1000);

      // Determine the background color based on messaged time and story type
      if ((story_type === 'support_ticket_out' || story_type === 'support_ticket_in') && complete_status === 'pending' && activeStatus === '0') {
        if (diffSeconds >= 60 && diffSeconds <= 120) {
          return '#82C8E2';
        } else if (diffSeconds > 120 && diffSeconds <= 180) { // Changed the second condition from >= to >
          return '#FFFFC3';
        } else if (diffSeconds > 180) { // Removed the redundant condition
          return '#FEC9C9';
        } else if (diffSeconds >= 180 && count === 1) { // Changed the condition order and removed >= 180
          return '#FEC9C9';
        }
      } else {
        return 'white'; // Default background color
      }
    } else {
      return 'white'; // Default background color
    }
  }
};

export function convertPSTtoLocalTimeWOTZ(time) {
  if (!time) {
    return null; // Return current date if input time is not provided
  }

  // Local time zone (system timezone)
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  // Convert the input time to a Moment.js object in PST timezone
  const pstMoment = moment.tz(time, 'America/Los_Angeles'); // 'America/Los_Angeles' is the IANA timezone identifier for PST

  // Convert the PST time to the local time zone
  const localTime = pstMoment.clone().tz(timeZone);

  // Return the local time as a Date object
  return localTime.toDate();
}

export const MESSAGE_REFRESH_TIMER = 60000

export const getMachineTimeZONE = () => {
  // eslint-disable-next-line
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}