import React, { useEffect, useState } from "react";
import ReportsAPI from "../../Common/AgileNetwork/ReportsAPI";
import { DateRangePicker } from "react-date-range";
import { startOfWeek, endOfWeek } from "date-fns";
import AdminTopNav from "../Utility/TopNav";
import AdminSideBar from "../Utility/SideNav";
import { Modal } from "react-bootstrap";
import RootLoader from "../../Common/Loader/RootLoader";
import { useWebProperties } from "../../Common/webProperties";
import moment from "moment";
import Select from "react-select";
import Alert from "../../Common/Alert";
import convertUTCtoLocalTime from "./convertUTCtoLocalTime";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";


export default function UserActivityLogs() {
    const { SELECT_DATE_RANGE, DATE } = useWebProperties();
    const getUser = useSelector(state => state.auth)
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [show, setShow] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [userActivities, setUserActivities] = useState([])
    const [result, setResult] = useState([])
    const [filteredResult, setFilteredResult] = useState([])
    const [loader, setLoader] = useState(false);
    const [message, setMessage] = useState("")
    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('');

    const [filter, setFilter] = useState([
        {
            startDate: startOfWeek(new Date(), { weekStartsOn: 0 }),
            endDate: endOfWeek(new Date(), { weekEndsOn: 7 }),
            key: "selection",
        },
    ]);
    const [logsFilter, setLogsFilter] = useState({})

    const getUserActivities = async () => {
        try {
            const response = await ReportsAPI.post('/logs/getUserActivities',{type:false})
            setUserActivities(response)
        } catch (error) {
            setUserActivities([])
            Alert('error', error.message)
        }

    }
    const handleSubmit = async () => {
        try {
            setLoader(true);
            setMessage('');
            const response = await ReportsAPI.post('/logs/getLogs', {
                filter_options: {
                    ...logsFilter,
                    date_filter: {
                        startDate: filter[0].startDate.toISOString(),
                        endDate: filter[0].endDate.toISOString()
                    },
                    account_id : getUser.user.account_id,
                    user_name : getUser.user.userName
                }
            })
            setResult(response)
            setFilteredResult(response)
            setLoader(false)
            setSearchQuery('')

        } catch (error) {
            setLoader(false)
            setSearchQuery('')
            setMessage(error.message)
            console.log('error', error.message)
        }
    }

    useEffect(() => {
        getUserActivities()
        handleSubmit()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const sortData = (data) => {
            return data.slice().sort((a, b) => {
                if(sortColumn==='timestamp'){
                    const a_date=new Date(a[sortColumn])
                    const b_date=new Date(b[sortColumn])
                    return sortDirection==='asc' ? a_date-b_date : b_date-a_date
                }
                else if(sortColumn==='user_id'){
                    const a_id=a.user_id.toLowerCase()
                    const b_id=b.user_id.toLowerCase()
                    return sortDirection==='asc'? a_id.localeCompare(b_id) : b_id.localeCompare(a_id)
                }
                else if(sortColumn==='logging_level'){
                    const a_level=parseInt(a.logging_level)
                    const b_level=parseInt(b.logging_level)
                    return sortDirection==='asc' ? a_level-b_level : b_level-a_level
                }
                else if(sortColumn==='location'){
                    const a_location=a.userInfo.location.toLowerCase()
                    const b_location=b.userInfo.location.toLowerCase()
                    return sortDirection==='asc'? a_location.localeCompare(b_location) : b_location.localeCompare(a_location)
                }
                else if(sortColumn==='activity_name'){
                    const a_name=a.activity_name.activity_name.toLowerCase()
                    const b_name=b.activity_name.activity_name.toLowerCase()
                    return sortDirection==='asc'? a_name.localeCompare(b_name) : b_name.localeCompare(a_name)

                }
                else if(sortColumn==='application'){
                    const a_name=a.application.toLowerCase()
                    const b_name=b.application.toLowerCase()
                    return sortDirection==='asc'? a_name.localeCompare(b_name) : b_name.localeCompare(a_name)

                }
                else{
                    return sortDirection==='asc'? a[sortColumn].toLowerCase().localeCompare(b[sortColumn].toLowerCase()):
                    b[sortColumn].toLowerCase().localeCompare(a[sortColumn].toLowerCase())
                }
            });
        };
        setFilteredResult(sortData(filteredResult))
        // eslint-disable-next-line
    }, [sortColumn, sortDirection]);

    const decodeLoggingLevel = (level) => {
        let logLevel = ''
        switch (level) {
            case 1:
                logLevel = 'Critical'
                break;
            case 2:
                logLevel = 'Error'
                break;
            case 3:
                logLevel = 'Debug'
                break;
            default:
                logLevel = null
                break;
        }
        return logLevel
    }

    const debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };
    const debouncedSearch = debounce((value) => {
        if (value.length > 0) {
            const filtered = result.filter(log =>
                (log.user_id && log.user_id.toLowerCase().includes(value.toLowerCase())) ||
                (log.userInfo && log.userInfo.ip && log.userInfo.ip.toLowerCase().includes(value.toLowerCase())) ||
                (log.userInfo && log.userInfo.location && log.userInfo.location.toLowerCase().includes(value.toLowerCase()))
            )
            setFilteredResult(filtered)
        }
        else {
            setFilteredResult(result)
        }
    }, 1000);
    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        debouncedSearch(event.target.value)
    };
    const getSortIcon = (column) => {
        return (
            <>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-up" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                </svg>
            </>
        );
    }

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    };
    const csv_data = [
        ["User Name","Time Stamp","User IP","Logging Level","User Location","User Activity","AI Prompt","Error Message"],
        ...(filteredResult.length > 0 
            ? filteredResult.map((log) => [
                log.user_id,
                convertUTCtoLocalTime(log.timestamp),
                (log.userInfo && log.userInfo.ip) ? log.userInfo.ip : '-',
                (log.logging_level) ? decodeLoggingLevel(log.logging_level) : '-',
                (log.userInfo && log.userInfo.location) ? log.userInfo.location : '-',
                (log.activity_name && log.activity_name.activity_name) ? log.activity_name.activity_name : '-',
                (log.ai_prompt) ? log.ai_prompt : 'NA',
                (log.error_message) ? log.error_message : 'NA'
            ])
            : [["No data available"]]
        )
    ];   

    return (
        <div className="container-scroller">
            <AdminTopNav />
            <div className="container-fluid page-body-wrapper">
                <AdminSideBar />
                <div className="main-panel">
                    <div className="mt-2">
                        <div className="col-lg-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">User Activity Logs</h4>
                                    <div className="d-flex justify-content-between row mb-2">
                                        <div className="d-flex">
                                            {
                                                <button
                                                    className="btn btn-success p-2 border"
                                                    style={{ marginLeft: 10 }}
                                                    onClick={handleShow}
                                                >
                                                    {SELECT_DATE_RANGE}
                                                </button>
                                            }
                                            {
                                                <div style={{ marginTop: 5 }}>
                                                    <text
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: "bold",
                                                            paddingLeft: 10,
                                                        }}
                                                        className=" text-success"
                                                    >
                                                        {" "}
                                                        {DATE}: {moment(filter[0].startDate).format("ll")} -{" "}
                                                        {moment(filter[0].endDate).format("ll")}{" "}
                                                    </text>
                                                </div>
                                            }
                                        </div>

                                        <div
                                            className="mt-1 pt"
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                marginRight: "3%"

                                            }}
                                        >
                                            <label className="mr-2 mb-0" htmlFor="searchInput">
                                                Search:
                                            </label>
                                            <input
                                                type="text"
                                                className=""
                                                style={{ height: "30px", width: "200px",border:'2px solid black',paddingLeft:'7px'}}
                                                aria-label="Search"
                                                aria-describedby="basic-addon2"
                                                onChange={handleSearch}
                                                value={searchQuery}
                                            />
                                        </div>

                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mt-4">
                                        <div className="d-flex row w-75">
                                            <div className="col-lg-5 col-sm-6">
                                                <Select
                                                    className=""
                                                    placeholder="User Activity"
                                                    isClearable={true}
                                                    options={userActivities.map(activity => ({
                                                        value: activity._id,
                                                        label: activity.activity_name
                                                    }))}
                                                    onChange={(selectedOption, triggeredAction) => {
                                                        if (triggeredAction.action === 'clear') {
                                                            const { activity_type, ...rest } = logsFilter;
                                                            setLogsFilter(rest);
                                                        }
                                                        else {
                                                            setLogsFilter({
                                                                ...logsFilter,
                                                                activity_type: selectedOption.value
                                                            })
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-3 col-sm-6">
                                                <Select
                                                    className="form-control"
                                                    placeholder="Logging Level"
                                                    isClearable={true}
                                                    onChange={(selectedOption, triggeredAction) => {
                                                        if (triggeredAction.action === 'clear') {
                                                            const { logging_level, ...rest } = logsFilter;
                                                            setLogsFilter(rest);
                                                        }
                                                        else {
                                                            setLogsFilter({
                                                                ...logsFilter,
                                                                logging_level: selectedOption.value
                                                            })
                                                        }
                                                    }}
                                                    options={[
                                                        { value: 1, label: "Critical" },
                                                        { value: 2, label: "Error" },
                                                        { value: 3, label: "Debug" }
                                                    ]}
                                                />
                                            </div>
                                            <button
                                                className="btn btn-primary ml-3" style={{ height: '38px' }} type="button"
                                                onClick={handleSubmit}
                                            >Submit</button>
                                        </div>
                                        <div className="" style={{marginRight:'10%'}}>
                                            <CSVLink className="downloadbtn btn btn-primary "
                                                filename={`LogReports-${new Date().getFullYear()}.csv`}
                                                data={csv_data}>Export</CSVLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 ">
                                    <Modal
                                        style={{ textAlign: "center" }}
                                        size="xl"
                                        show={show}
                                        onHide={handleClose}
                                    >
                                        <Modal.Header closeButton></Modal.Header>
                                        <Modal.Body>
                                            <DateRangePicker
                                                onChange={(item) => setFilter([item.selection])}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                months={2}
                                                ranges={filter}
                                                direction="horizontal"
                                            />
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <button
                                                className="btn btn-success"
                                                type="button"
                                                onClick={() => {
                                                    handleClose()
                                                    handleSubmit()
                                                }}
                                            >
                                                Search
                                            </button>
                                        </Modal.Footer>
                                    </Modal>
                                    {/* </div> */}
                                </div>
                                {
                                    message && (<><br /><br /><span style={{ color: 'red', marginLeft: '10%' }}>{message}</span></>)
                                }
                                <div className="p-2">
                                    <div className='pt-2' />
                                    {filteredResult.length > 0 && !loader ?
                                        <div className="table-responsive">
                                            <table
                                                search="true"
                                                className='table table-bordered mb-1 rwd-table' id="user-info-table"
                                                data-pagination="true"
                                            >
                                                <thead style={{ backgroundColor: '#F4FAF7' }}>
                                                    <tr>
                                                        <th onClick={()=>handleSort('user_id')}>User Name {getSortIcon()}</th>
                                                        <th onClick={()=>handleSort('timestamp')}>Time Stamp {getSortIcon()}</th>
                                                        <th>User IP</th>
                                                        <th onClick={()=>handleSort('logging_level')}>Logging Level {getSortIcon()}</th>
                                                        <th onClick={()=>handleSort('location')}>User Location {getSortIcon()}</th>
                                                        <th onClick={()=>handleSort('activity_name')}>User Activity {getSortIcon()}</th>
                                                        <th onClick={()=>handleSort('application')}>Application {getSortIcon()}</th>
                                                        <th>Response Time</th>
                                                        <th>Error Message</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        filteredResult.map((log) => {
                                                            return (
                                                                <tr key={log._id}>
                                                                    <td style={{ height: 40, fontWeight: 'bold', color: 'green' }}>{log.user_id}</td>
                                                                    <td><p style={{ fontWeight: 'bold' }}>{convertUTCtoLocalTime(log.timestamp)}</p></td>
                                                                    <td><p style={{ fontWeight: 'bold' }}>{log.userInfo.ip || '-'}</p></td>
                                                                    <td><p style={{ fontWeight: 'bold' }}>{decodeLoggingLevel(log.logging_level) || '-'}</p></td>
                                                                    <td><p style={{ fontWeight: 'bold' }}>{log.userInfo.location || '-'}</p></td>
                                                                    <td><p style={{ fontWeight: 'bold' }}>{`${log.activity_name.activity_name ?? '-'}` + (log.sub_activity ? ` - ${log.sub_activity}` : '')}</p></td>
                                                                    <td><p style={{ fontWeight: 'bold' }}>{log.application || '-'}</p></td>
                                                                    <td><p style={{ fontWeight: 'bold' }}>{log.response_time ? `${log.response_time} ms` : '-'}</p></td>
                                                                    <td><p style={{ fontWeight: 'bold' }}>{log.error_message || 'NA'}</p></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        : loader ?
                                            <RootLoader /> :
                                            null}
                                </div>





                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
